* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  /* position: relative; */
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.section-container {
  max-width: 1170px;
  width: 100%;
}

/* spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #b50a0c;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #b50a0c transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/***
=============================================
    Thm Btn
=============================================
***/
.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  background-color: #b50a0c;
  color: #ffffff;
  font-size: 12px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
  border: 1px solid #b50a0c;
}

.thm-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: transparent;
  border: 1px solid #b50a0c;
  color: #ffffff;
  transition: 0.5s;
  transform-origin: bottom;
  transform: translateY(-100%) rotateX(90deg);
}

.thm-btn:hover::before {
  transform: translateY(0) rotateX(0deg);
}

.thm-btn::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #b50a0c;
  color: #ffffff;
  transition: 0.5s;
  transform-origin: top;
  transform: translateY(0) rotateX(0deg);
}

.thm-btn:hover::after {
  transform: translateY(100%) rotateX(90deg);
}

/* nav and mobile-nav */
.mobile-wrapper {
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  transform-origin: right center;
  transition: all 900ms ease-in-out, opacity 500ms linear,
    visibility 500ms ease-in;
}

.mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.left0 {
  top: 0;
  left: 0;
}

.left-100 {
  top: -150%;
  left: 0;
}

.mobile-nav_content {
  width: 300px;
  background: white;
  color: #000;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
}

.mobile-nav_container,
.mobile-menu > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-menu {
  list-style-type: none;
  align-items: center;
}

/* why */
.heroe-overlay {
  width: 100%;
  height: 100%;
  padding: 10rem 0 7rem;
}

@media screen and (max-width: 876px) {
  .red-bg {
    flex-direction: column;
    /* gap: 10; */
  }
}

/* responsive nav */
@media screen and (min-width: 900px) {
  .navBarToggle {
    display: none;
  }
  .navMenu {
    display: flex;
  }
  .navMobileVisibility {
    display: none;
  }
}

/* home OEM product section */
@media screen and (max-width: 1023px) {
  .homeseventhSection-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: 740px) {
  .homeseventhSection-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .solutionsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
